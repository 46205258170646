<template>
  <div class="mt-24 relative massive-margin-bottom" id="membership">
    <div
      class="text-white huge-font-size leading-none absolute right-0 text-right"
    >
      COMIENZA <br />
      HOY MISMO
    </div>
    <div class="absolute top-64 text-left">
      <div class="w-full flex flex-col">
        <div class="text-5xl pl-4">Planes de Pago</div>
      </div>
    </div>

    <div class="absolute top-80 w-full">
      <div class="p-4 justify-items-end">
        <template>
          <div class="w-full pricing-container">
            <div>
              <div class="text-xl mb-4">
                Toma el control de tu negocio con una sola aplicacion
              </div>
              <sa-toggle
                option1="Mensual"
                option2="Anual"
                selectedBackground="linear-gradient(45deg, #a857f6 0%, #f59911 100%)"
                @option-changed="updatePricing"
              ></sa-toggle>
            </div>

            <div
              class="pricing-item basic rounded-tl-3xl"
              style="border: 1px solid lightgray; border-bottom: none"
            >
              <div class="text-xl">Basico</div>
              <div class="price">
                <span class="dollars">$ {{ basic.dollars }}</span>
                <span class="cents">.{{ basic.cents }}</span>
                <span class="currency text-gray-400">USD/Mes</span>
              </div>
              <div>Gestionador de Tareas</div>
              <div class="text-purple-500">Usuarios Ilimitados</div>
              <div>(1 Sucursal)</div>
            </div>

            <div class="pricing-item pro large rounded-t-3xl">
              <div class="text-xl">Pro</div>
              <div class="price">
                <span class="dollars">$ {{ pro.dollars }}</span>
                <span class="cents">.{{ pro.cents }}</span>
                <span class="currency">USD/Mes</span>
              </div>
              <div>Gestionador Completo</div>
              <div>Usuarios Ilimitados</div>
              <div>(1 a 3 Sucursales)</div>
            </div>

            <div
              class="pricing-item pro-franchise rounded-tr-3xl"
              style="border: 1px solid lightgray; border-bottom: none"
            >
              <div class="text-xl">Pro Franquicias</div>
              <div class="price">
                <span class="dollars">$ {{ franchise.dollars }}</span>
                <span class="cents">.{{ franchise.cents }}</span>
                <span class="currency text-gray-400">USD/Mes</span>
              </div>
              <div>Gestionador Completo</div>
              <div class="text-purple-500">Usuarios Ilimitados</div>
              <div>(3 o más Sucursales)</div>
            </div>
          </div>
          <div
            v-for="app in apps"
            :key="app.id"
            class="w-full bg-white leading-10 pricing-grid"
          >
            <div clas="w-full">
              {{ app.name }}
            </div>
            <div
              clas="w-full"
              style="text-align: center; border-left: 1px solid lightgray"
            >
              <i
                v-if="app.basic"
                class="text-xl bx text-purple-500"
                :class="{
                  'bxs-check-circle': app.basic,
                  'bx-check-circle': !app.basic,
                }"
              ></i>
            </div>
            <div
              clas="w-full"
              style="
                transform: scaleX(1.2);
                background-color: rgba(139, 92, 246, 0.6);
                text-align: center;
              "
            >
              <i
                class="text-xl bx text-white"
                :class="{
                  'bxs-check-circle': app.pro,
                  'bx-check-circle': !app.pro,
                }"
              ></i>
            </div>
            <div
              clas="w-full"
              style="text-align: center; border-right: 1px solid lightgray"
            >
              <i
                class="text-xl bx text-purple-500"
                :class="{
                  'bxs-check-circle': app.franchise,
                  'bx-check-circle': !app.franchise,
                }"
              ></i>
            </div>
          </div>
          <div class="w-full grid-columns h-8">
            <div
              clas="w-full"
              style="background-color: rgb(243, 244, 246) !important"
            ></div>
            <div
              class="p-2"
              style="
                width: 90%;
                background-color: white;
                text-align: center;
                border-bottom-left-radius: 24px;
                border-left: 1px solid lightgray;
                border-bottom: 1px solid lightgray;
              "
            >
              <sa-button
                class="p-1"
                @click="$router.push({ name: 'register.index' })"
              >
                Pruebalo gratis
              </sa-button>
            </div>
            <div
              clas="w-full"
              style="
                transform: scaleY(1.5) scaleX(1.2);
                transform-origin: top;
                background-color: rgba(139, 92, 246, 0.6);
                text-align: center;
                border-bottom-left-radius: 24px;
                border-bottom-right-radius: 24px;
                padding: 8px;
              "
            >
              <el-button
                class="p-1 text-xs"
                @click="$router.push({ name: 'register.index' })"
              >
                Comenzar Ahora
              </el-button>
            </div>
            <div
              style="
                width: 90%;
                background-color: white;
                text-align: center;
                border-bottom-right-radius: 24px;
                border-right: 1px solid lightgray;
                border-bottom: 1px solid lightgray;
                justify-self: right;
                padding: 8px;
              "
            >
              <sa-button
                class="p-1"
                @click="$router.push({ name: 'register.index' })"
              >
                Comenzar Ahora
              </sa-button>
            </div>
          </div>
        </template>
      </div>
    </div>
  </div>
</template>

<style scoped>
.pricing-container {
  display: grid;
  grid-template-columns: repeat(4, 1fr);
}
.pricing-item {
  background-color: white;
  padding: 20px;
  box-shadow: 0px 10px 20px rgba(0, 0, 0, 0.1);
  display: flex;
  flex-direction: column;
  justify-content: center;
  text-align: center;
}
.pricing-item .text-xl {
  font-size: 1.25rem;
}
.pricing-item .price {
  font-size: 2.5rem;
  font-weight: bold;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: baseline;
}
.pricing-item .price .dollars {
  font-size: 2.5rem;
}
.pricing-item .price .cents {
  font-size: 1.5rem;
  margin-left: 0.25rem;
}
.pricing-item .price .currency {
  font-size: 1rem;
  margin-left: 0.5rem;
}
.pricing-item.large {
  grid-row: span 2;
  background-color: #f3f4f6;
  transform: scale(1.2);
  transform-origin: bottom;
}
.pricing-item.pro {
  background-color: rgb(139, 92, 246) !important;
  color: white;
}
.pricing-item.basic {
  background-color: #ffffff;
}
.pricing-item.pro-franchise {
  background-color: #ffffff;
}
.pricing-container > .pricing-item {
  padding: 16px;
  box-shadow: 0 10px 30px rgba(0, 0, 0, 0.1);
}
.pricing-grid {
  display: grid;
  grid-template-columns: repeat(4, 1fr);
}
.pricing-grid:nth-child(even) {
  background-color: #eaeaea;
}
.pricing-item.pro.large {
  position: relative;
  grid-row: span 2;
  background-color: #f3f4f6;
  transform: scale(1.2);
  transform-origin: bottom;
  overflow: hidden;
}
.grid-columns {
  display: grid;
  grid-template-columns: repeat(4, 1fr);
}
.pricing-item.pro.large::before {
  content: "Best Pricing";
  position: absolute;
  bottom: 80px;
  left: 0px;
  width: 150px;
  height: 30px;
  background-color: #f59911;
  color: white;
  font-size: 0.8rem;
  font-weight: bold;
  text-align: center;
  line-height: 29px;
  transform: rotate(-45deg);
  transform-origin: bottom left;
  z-index: 1;
}
</style>
<script>
import { PRICING } from "../configuration";
export default {
  name: "Pricing",
  data() {
    return {
      currentPricing: "Anual",
      apps: PRICING,
    };
  },
  computed: {
    basic() {
      if (this.currentPricing === "Anual") {
        return { dollars: 16, cents: 70 };
      }
      return { dollars: 19, cents: 99 };
    },
    pro() {
      if (this.currentPricing === "Anual") {
        return { dollars: 29, cents: 16 };
      }
      return { dollars: 34, cents: 99 }; // TO UPDATE
    },
    franchise() {
      if (this.currentPricing === "Anual") {
        return { dollars: 41, cents: 65 };
      }
      return { dollars: 49, cents: 99 }; // TO UPDATE
    },
  },
  methods: {
    updatePricing(pricing) {
      this.currentPricing = pricing;
    },
  },
};
</script>
